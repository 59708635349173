<template>
  <div class="images">
    <br>
    <ATitle text="图片"/>
    <div class="center">
      <div class="flex images-wrap">
        <router-link v-for="(item,index) in list" :key="index" tag="a" :to="'/news/'+item.id" class="images-item">
          <div class="img"><img :src="item.logoUrl"></div>
          <div class="bottom">
            <div class="title">{{item.title}}</div>
          </div>
        </router-link>
      </div>
      <div style="height:32px;margin-bottom: 20px">
        <Page :total="page.total" :page-size="page.limit" @on-change="pageChange" style="float:right"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: {
        total: 0,
        limit: 9,
        page: 1,
        type: 3
      },
      aboutList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    pageChange(e) {
      this.page.page = e
      this.getList()
    },
    getList() {
      this.$http.get('/meeting/news/page/wxff1c03d44d5b330b',{ // 普通新闻
        params: this.page
      }).then(res => {
        if (res.code === 0) {
          this.list = res.data.list
          this.page.total = res.data.total
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.$Message.error(err)
      })
    },
  }
}
</script>
<style lang="less" scoped>
.images {
  min-height: calc(100vh - 470px);
  .images-wrap {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 560px;
  }
  .images-item {
    transition: all ease-in-out 0.3s;
    display: block;
    cursor: pointer;
    height: 272px;
    width: 333px;
    margin: 13px;
    border: 1px solid #CCC;
    .img {
      height: 210px;
      overflow: hidden;
      img {
        transition: all ease-out 0.2s;
        width: 333px;
        height: 210px;
      }
    }
    .bottom {
      height: 60px;
      background: #F4F4F4;
      padding: 10px;
      .title {
        text-align: center;
        height: 40px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        line-height: 22px;
      }
    }
  }
  .images-item:hover {
    box-shadow: 0 0 5px #125FAD;
    img {
      transform: scale(1.05);
    }
    .title {
      color: #125FAD !important;
    }
  }
}
</style>